import { FC, PropsWithChildren } from "react";
import { Footer, Header, Main } from "ui";

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Header />
			<Main>{children}</Main>
			<Footer />
		</>
	);
};
