import { apiPlugin, getStoryblokApi, StoryblokComponent, storyblokInit, useStoryblokState } from "@storyblok/react";
import { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { GetStaticPropsResult } from "next/types";
import { ISbStoriesParams } from "storyblok-js-client/src/interfaces";
import { Paragraph, Section } from "ui";
import { storyblokComponentsConfig } from "./config";
interface PageData {
	story: any;
	preview: boolean;
}
storyblokInit({
	accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
	use: [apiPlugin],
	components: storyblokComponentsConfig,
});
export const StoryblokModule: NextPage<PageData> = ({ story, preview = false }) => {
	story = useStoryblokState(story, {});
	return (
		<>
			{!preview && <div id={"storyblok-javascript-bridge"} />}
			{preview && (
				<a
					href={"/api/exit-preview"}
					style={{
						zIndex: 999999,
						position: "fixed",
						bottom: "0%",
						left: "50%",
						transform: "translateX(-50%)",
						background: "yellow",
						padding: "1rem",
						color: "#000",
					}}>
					Exit Preview Mode
				</a>
			)}
			{!story && !story?.content && (
				<Section fullHeight={true} verticalAlignment={"center"} horizontalAlignment={"start"}>
					<Paragraph textCenter={true}>Loading...</Paragraph>
					{!preview && <div id={"storyblok-javascript-bridge"} />}
				</Section>
			)}
			{story?.content && <StoryblokComponent blok={story.content} />}
		</>
	);
};
export const getStaticProps: GetStaticProps = async ({
	params,
	preview = false,
}): Promise<GetStaticPropsResult<PageData>> => {
	try {
		let overViewData = false;
		let slug = params?.slug ?? process.env.STORYBLOK_HOMEPAGE_ROUTE;
		if (Array.isArray(slug)) slug = slug.join("/");
		let sbParams: ISbStoriesParams = {
			version: process.env.NODE_ENV === "production" ? "published" : "draft",
		};
		if (preview) {
			sbParams.version = "draft";
		}
		let { data } = await getStoryblokApi().get(`cdn/stories/${slug}`, sbParams);
		if (data?.story?.content?.component === "post_overview") {
			let { data: childData } = await getStoryblokApi().get(`cdn/stories`, {
				starts_with: data.story.full_slug,
				...sbParams,
			});
			overViewData = childData?.stories
				.filter(({ is_startpage }) => is_startpage === false)
				.map(({ full_slug, content }) => {
					return {
						full_slug,
						image: content?.image ?? null,
						title: content?.title ?? null,
						date: content?.date ?? null,
					};
				});
			data.story.content.overViewData = overViewData;
		}
		if (data?.story) {
			return {
				props: {
					story: data.story,
					preview,
				},
				revalidate: 60,
			};
		}
		return {
			notFound: true,
			revalidate: 60,
		};
	} catch (e) {
		//console.log(e);
		return {
			notFound: true,
			revalidate: 60,
		};
	}
};
export const getStaticPaths: GetStaticPaths = async () => {
	let { data } = await getStoryblokApi().get("cdn/links/");
	let paths = [];
	Object.keys(data.links).forEach((linkKey) => {
		if (data.links[linkKey].is_folder) {
			return;
		}
		const slug = data.links[linkKey].slug;
		let splittedSlug = slug.split("/");
		if (slug === process.env.STORYBLOK_HOMEPAGE_ROUTE) splittedSlug = false;
		paths.push({ params: { slug: splittedSlug } });
	});

	return {
		paths,
		fallback: true,
	};
};
