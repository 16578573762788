import { BaseLayout } from "layouts/BaseLayout";
import { ReactElement } from "react";
import { getStaticPaths, getStaticProps, StoryblokModule } from "storyblok/src/StoryblokModule";
import { NextPageWithLayout } from "./_app";
export { getStaticProps, getStaticPaths };
const _StoryblokModule = StoryblokModule as NextPageWithLayout;
_StoryblokModule.getLayout = function getLayout(page: ReactElement) {
	return <BaseLayout>{page}</BaseLayout>;
};
export default _StoryblokModule;
